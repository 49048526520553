import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/output.css";

import axios from "./http/index"
Vue.prototype.$http = axios;

import GlobalPlugin from './utils/mixins/index'
Vue.use(GlobalPlugin)


// import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/index.scss";
// Vue.use(ElementUI);
import 'element-ui/lib/theme-chalk/index.css'
import element from './utils/initElement'
Vue.use(element)

// import 'v-charts/lib/style.css';
// import VCharts from 'v-charts'
// Vue.use(VCharts)
// import VeLine from 'v-charts/lib/line.common';
// import VeHistogram from 'v-charts/lib/histogram.common';
// import VeRing from 'v-charts/lib/ring.common';

// Vue.component(VeLine.name, VeLine)
// Vue.component(VeHistogram.name, VeHistogram)
// Vue.component(VeRing.name, VeRing)

// import BaiduMap from 'vue-baidu-map';
// Vue.use(BaiduMap, {
//   /* Visit http://lbsyun.baidu.com/apiconsole/key for details about app key. */
//   ak: 'qSo9j1Y7xy5lqaLQ2h1djrCWQKtOzEoa'
// })

Vue.config.productionTip = false;

// import util from './utils/util.js'
// Vue.prototype.util = util;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
